<template>
	<div id="app">
		<c-hero @showModal="onToggleModal"/>

		<section class="section section--feature">
			<i class="decor d1"></i>
			<i class="decor d2"><svg fill="#343740" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><circle cx="10" cy="10" r="10"/></svg></i>

			<div class="container-xl">
				<div class="row justify-content-sm-center justify-content-md-end">
					<div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
						<div class="section-heading">
							<h2 class="section-heading__title">They are waiting for you today</h2>
						</div>

						<div class="features">
							<div class="features__inner">
								<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
									class="features__item"
								>
									<i class="features__img">
										<img :src="oFeature.img" alt="">
									</i>
									<div class="features__title">{{ oFeature.title }}</div>
									<p>{{ oFeature.content }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section">
			<div class="container-xl">
				<div class="section-heading">
					<h2 class="section-heading__title">Profiles</h2>
				</div>

				<div class="profile">
					<div class="profile__slider" ref="glide">
						<div data-glide-el="track" class="glide__track">
							<div class="glide__slides">
								<div
									v-for="(oProfile, index) in aProfiles"
									:key="'glide__slide' + index"
									class="glide__slide"
								>
									<div class="profile__item"
										@click="onToggleModal"
									>
										<span class="profile__status">online</span>
										<figure class="profile__img">
											<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
										</figure>
										<div class="profile__info">
											<div class="profile__name">{{ oProfile.name }}, {{ oProfile.age }} years</div>
											<div class="profile__pref">
												<i v-for="i in 3" :key="'i' + i" :class="'i-' + i"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="glide__bullets" data-glide-el="controls[nav]">
							<button type="button" class="glide__bullet"
								v-for="index in aProfiles.length"
								:key="'glide__bullet' + index"
								:data-glide-dir="'=' + (index - 1)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<c-footer/>

		<transition name="fade">
			<div v-if="!bHideModal" class="modal">
				<div class="modal__inner">
					<button class="modal__close" @click="onToggleModal" type="button"></button>

					<form-signup />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import Glide     from '@glidejs/glide';
import scrolling from '@/utils/scrolling';

import Hero       from '@/components/Hero.vue';
import Footer     from '@/components/Footer.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'App',

	components: {
		'c-hero': Hero,
		'c-footer': Footer,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,

			aProfiles: [],
			aFeatures: Object.freeze([
				{
					img: require('@/assets/features/1.svg'),
					title: 'Add Profile',
					content: 'More than ten thousand girls from different parts of the world are waiting for you, their personal photo videos, as well as the opportunity to chat with them in video chat'
				},
				{
					img: require('@/assets/features/2.svg'),
					title: 'Profile',
					content: 'After registering on our resource, you will get unlimited access to viewing girls\' profiles'
				},
				{
					img: require('@/assets/features/3.svg'),
					title: 'Love Story',
					content: 'Thousands of girls are ready for video calls and real dates, do not waste a minute, join us as soon as possible so as not to waste your unique chance to meet a beautiful woman!'
				}
			])
		};
	},

	created: async function ()
	{
		this.aProfiles = await this.fetchUsers();

		this.$nextTick(this.init);

		await this.sendPidAndSid();
	},

	mounted: function ()
	{
		
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			var options = {
					method: 'post',
					url: '/api/?action=get_profiles'
				};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;

			var oSearchParams = new URL(document.location).searchParams,
				sData = 'pid=' + oSearchParams.get('pid') + '&sid=' + oSearchParams.get('sid') + '&referer=' + document.referrer,
				options = {
					method: 'post',
					url: '/api/?action=send_partner_info',
					data: sData
				};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		},

		init: function ()
		{
			var option = {
					type: 'carousel',
					perView: 4,
					gap: 30,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 500,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)',
					breakpoints: {
						991: {
							perView: 3
						},
						767: {
							perView: 2.2
						},
						575: {
							perView: 1.4,
							gap: 10,
							focusAt: 'center'
						}
					}
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}
</script>

<style lang="scss">
@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import './styles/style';

#app
{
	overflow-x: hidden;
}

.section
{
	&--feature
	{
		background-color: #343740;

		.decor
		{
			position: absolute;
			border-radius: 50%;
			z-index: -1;

			&.d1
			{
				width: 285px;
				top: 70%;
				left: 0;
				background-color: rgba(#FF5510, 0.5);
				transform: translateY(-50%) translateX(-50%);

				&::before
				{
					content: '';
					display: block;
					padding-top: 100%;
				}

				@media (min-width: 992px)
				{
					top: 50%;
				}
			}

			&.d2
			{
				display: none;
				top: -10%;
				bottom: -10%;
				left: 40%;
				box-shadow: -20px 20px 200px 50px #fff;

				svg
				{
					display: block;
					height: 100%;
				}

				@media (min-width: 768px)
				{
					display: block;
				}

				@media (min-width: 992px)
				{
					left: 45%;
				}

				@media (min-width: 1200px)
				{
					left: 50%;
				}
			}
		}

		.section-heading
		{
			&__title
			{
				color: #FF5510;
			}
		}

		@media (min-width: 768px)
		{
			background: url('~@/assets/feature-bg.jpg') no-repeat 20% center;
			background-size: cover;

			.section-heading
			{
				&__title
				{
					text-align: right;
				}
			}
		}
	}
}

.features
{
	$offset-Y: 70px;

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		padding-left: 80px;
		color: #F2F2F2;
	}

	&__img
	{
		position: relative;
		float: left;
		margin-left: -80px;
		height: 70px;

		img
		{
			display: inline-block;
			max-height: 100%;
		}
	}

	&__title
	{
		margin-bottom: 10px;
		line-height: 1;
		font-size: 2.5rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	@media (min-width: 576px)
	{
		
	}

	@media (min-width: 768px)
	{
		&__title
		{
			margin-top: 20px;
		}
	}

	@media (min-width: 1200px)
	{
		$offset-Y: 100px;

		&__inner
		{
			margin-bottom: $offset-Y * -1;
		}

		&__item
		{
			margin-bottom: $offset-Y;
		}
	}
}

.profile
{
	$offset-Y: 30px;

	.glide
	{
		&__track
		{
			margin-left: -15px;
			margin-right: -15px;
		}

		&__slides
		{
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		cursor: pointer;
		background-color: #FFF;
		border-radius: 20px;
		box-shadow: 0px 4px 10px rgba(41, 40, 40, 0.25);

		&:hover
		{
			img
			{
				transform: scale(1.2) translateZ(0);
			}
		}
	}

	&__status
	{
		position: absolute;
		top: 15px;
		right: 15px;
		min-width: 65px;
		height: 22px;
		line-height: 22px;
		font-size: 1.4rem;
		font-style: normal;
		text-align: center;
		color: #FFF;
		background-color: #FF5510;
		border-radius: 5px;
		z-index: 1;
	}

	&__img
	{
		position: relative;
		overflow: hidden;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		background-color: #333;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(330/270);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.75;
			object-fit: cover;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
			);
		}
	}

	&__info
	{
		padding: 20px 15px 15px;
		text-align: center;
		color: #333;
	}

	&__name
	{
		margin-bottom: 15px;
		line-height: 1.2;
		font-size: 1.6rem;
		font-weight: 700;
	}

	&__pref
	{
		$size: 24px;

		margin-left: -5px;

		display: flex;
		align-items: center;
		justify-content: space-evenly;
		line-height: $size;
		font-size: 1.6rem;

		i
		{
			display: inline-block;
			vertical-align: top;
			width: $size;
			height: $size;
			margin-left: 5px;
			background-position: center;
			background-repeat: no-repeat;
		}

		.i-1
		{
			background-image: url('~@/assets/preferences/1.svg');
		}
		.i-2
		{
			background-image: url('~@/assets/preferences/2.svg');
		}
		.i-3
		{
			background-image: url('~@/assets/preferences/3.svg');
		}
	}

	.glide__bullets
	{
		margin-top: 20px;
	}

	@media (min-width: 576px)
	{
		&__name
		{
			font-size: 2rem;
		}
	}
}

.glide
{
	&__bullets
	{
		font-size: 0;
		text-align: center;
	}

	&__bullet
	{
		width: 16px;
		height: 16px;
		margin-left: 20px;
		cursor: pointer;
		background-color: transparent;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 0 3px #FF5510 inset;
		transition: box-shadow 300ms ease-in;

		&:first-child
		{
			margin-left: 0;
		}

		&--active,
		&:hover
		{
			box-shadow: 0 0 0 8px #FF5510 inset;
		}

		&--active
		{
			cursor: default;
		}
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	text-align: center;
	background-color: rgba(52, 55, 64, 0.4);
	overflow: auto;
	z-index: 10;
	backface-visibility: hidden;
	backdrop-filter: blur(10px);

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 90%;
		max-width: 700px;
		padding: 75px 30px;
		background-color: #FFF;
		box-shadow: 0px 6px 10px rgba(52, 55, 64, 0.05);
		border-radius: 30px;
	}

	&__close
	{
		position: absolute;
		top: -20px;
		right: -10px;
		padding: 55px 55px 0 0;
		background: #FFAD8B;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #FFF;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2.8rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1.4rem;
		}

		&::after
		{
			width: 4px;
			height: 2.8rem;
			left: 50%;
			top: 50%;
			margin-top: -1.4rem;
			margin-left: -2px;
		}
	}

	@media (min-width: 768px)
	{
		&__inner
		{
			padding-left: 70px;
			padding-right: 70px;
			border-radius: 50px;
		}
	}
}
</style>
